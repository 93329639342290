import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context";
import AppHeader from "../shared/AppHeader";
import Notification from "../shared/Notification";
import Input from "../shared/Input";
import Select from "../shared/Select";
import Close from "../shared/Close";
import { useHttpClient } from "../../utils/http-hook";
import Placeholder from "../../assets/placeholder-tile.jpg";
import Download from "../../assets/download.svg";
import Loader from "../shared/Loader";
import MultiSelect from "../shared/MultiSelect";
import EnergyLevels from "../shared/EnergyLevels";

const ControlPanel = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const [songs, setSongs] = useState(
    context.songs
      ? context.songs.filter((s) => !s.AIGenerated && s.AIEnabled)
      : []
  );

  const artists = songs.map((s) => {
    return { name: s.artist, value: s.artist };
  });

  const [activeArtist, setActiveArtist] = useState(artists[0]?.value);
  const [artistSongs, setArtistSongs] = useState(
    songs.filter((s) => s.artist === artists[0].value)
  );
  const [activeArtistSong, setActiveArtistSong] = useState(
    songs.filter((s) => s.artist === artists[0].value)[0]
  );
  const [parameters, setParameters] = useState({
    moods: [],
    genres: [],
    muteStems: [],
    tempo: [],
    energyLevels: [],
  });

  useEffect(() => {
    context.setMode("Rock");
  }, []);

  useEffect(() => {
    setArtistSongs(songs.filter((s) => s.artist === activeArtist));
  }, [songs]);

  useEffect(() => {
    setArtistSongs(songs.filter((s) => s.artist === activeArtist));
  }, [activeArtist]);

  useEffect(() => {
    setActiveArtistSong(artistSongs[0]);
  }, [artistSongs]);

  useEffect(() => {
    context.setActiveSong(activeArtistSong ? activeArtistSong._id : "");

    setParameters({
      moods: activeArtistSong?.aiParameters?.[context.mode]?.moods || [],
      genres: activeArtistSong?.aiParameters?.[context.mode]?.genres || [],
      muteStems:
        activeArtistSong?.aiParameters?.[context.mode]?.muteStems || [],
      tempo: activeArtistSong?.aiParameters?.[context.mode]?.tempo || [],
      energyLevels:
        activeArtistSong?.aiParameters?.[context.mode]?.energyLevels || [],
    });
  }, [activeArtistSong, context.mode]);

  useEffect(() => {
    context.audioRef.current && context.audioRef.current.pause();
  }, [context.activeSong]);

  useEffect(() => {
    // Sync context songs to local songs state
    setSongs(
      context.songs
        ? context.songs.filter((s) => !s.AIGenerated && s.AIEnabled)
        : []
    );
  }, [context.songs]);

  const updateHandler = async () => {
    context.setSongs((songs) => {
      const index = songs.findIndex((s) => s._id === activeArtistSong._id);
      songs[index] = {
        ...songs[index],
        aiParameters: {
          ...songs[index].aiParameters,
          [context.mode]: {
            moods: parameters.moods,
            genres: parameters.genres,
            muteStems: parameters.muteStems,
            tempo: parameters.tempo,
            energyLevels: parameters.energyLevels,
          },
        },
      };
      return songs;
    });

    setSongs((songs) => {
      const index = songs.findIndex((s) => s._id === activeArtistSong._id);
      songs[index] = {
        ...songs[index],
        aiParameters: {
          ...songs[index].aiParameters,
          [context.mode]: {
            moods: parameters.moods,
            genres: parameters.genres,
            muteStems: parameters.muteStems,
            tempo: parameters.tempo,
            energyLevels: parameters.energyLevels,
          },
        },
      };
      return songs;
    });

    setActiveArtistSong((active) => {
      return {
        ...active,
        aiParameters: {
          ...active.aiParameters,
          [context.mode]: {
            moods: parameters.moods,
            genres: parameters.genres,
            muteStems: parameters.muteStems,
            tempo: parameters.tempo,
            energyLevels: parameters.energyLevels,
          },
        },
      };
    });

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/songs/save-ai-params`,
      "POST",
      JSON.stringify({
        songId: activeArtistSong._id,
        mode: context.mode,
        settings: parameters,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );
  };

  return activeArtist ? (
    <div className="col col__1">
      <Notification text="Admin" />
      <AppHeader text="CONTROL PANEL" />
      <Close onClick={() => context.setTab()} />

      <div className="remix_info">
        <div className="remix_info__details">
          <img src={activeArtistSong.imgURL || Placeholder} alt="" />
          <div>
            <h3>{activeArtistSong.name}</h3>
            <p>{activeArtistSong.artist}</p>
            <p>{activeArtistSong.producer}</p>
            <p>{activeArtistSong.label}</p>
            <p>BPM: {activeArtistSong.bpm}</p>
            <p>Length: {activeArtistSong.length} [sec]</p>
            <p>
              Key: {activeArtistSong.key} {activeArtistSong.mode}
            </p>
            <p>Moods: {parameters.moods.join(", ")}</p>
            <p>Genres: {parameters.genres.join(", ")}</p>
            <p>Mute stems: {parameters.muteStems.join(", ")}</p>
            <p>Tempo: {parameters.tempo.join(", ")}</p>
            <p>Energy Levels: {JSON.stringify(parameters.energyLevels)}</p>
          </div>
        </div>

        <div className="remix_info__inputs">
          <Select
            data={artists}
            default={activeArtist}
            onChange={(value) => setActiveArtist(value)}
          />

          <Select
            data={artistSongs.map((s) => {
              return { value: s._id, name: s.name };
            })}
            default={activeArtistSong._id}
            onChange={(value) =>
              setActiveArtistSong(artistSongs.find((s) => s._id === value))
            }
          />

          <div className="remix_info__buttons">
            <MultiSelect
              placeholder="Moods"
              values={parameters.moods}
              setValues={(moods) =>
                setParameters((params) => {
                  return { ...params, moods };
                })
              }
              data={[
                "Angry",
                "Busy & Frantic",
                "Dark",
                "Dreamy",
                "Elegant",
                "Epic",
                "Euphoric",
                "Fear",
                "Funny & Weird",
                "Glamorous",
                "Happy",
                "Heavy & Ponderous",
                "Hopeful",
                "Laid Back",
                "Mysterious",
                "Peaceful",
                "Restless",
                "Romantic",
                "Running",
                "Sad",
                "Scary",
                "Sentimental",
                "Sexy",
                "Smooth",
                "Suspense",
              ]}
            />

            <MultiSelect
              placeholder="Genres"
              values={parameters.genres}
              setValues={(genres) =>
                setParameters((params) => {
                  return { ...params, genres };
                })
              }
              data={[
                "Acoustic",
                "Hip Hop",
                "Beats",
                "Funk",
                "Pop",
                "Drum n Bass",
                "Trap",
                "Tokyo night pop",
                "Rock",
                "Latin",
                "House",
                "Tropical House",
                "Ambient",
                "Orchestra",
                "Electro & Dance",
                "Electronica",
                "Techno & Trance",
                "Jersey Club",
                "Drill",
                "R&B",
                "Lofi Hip Hop",
                "World",
                "Afrobeats",
                "Christmas",
                "Sexy Drill",
              ]}
            />

            <MultiSelect
              placeholder="Tempo"
              values={parameters.tempo}
              setValues={(tempo) =>
                setParameters((params) => {
                  return { ...params, tempo };
                })
              }
              data={["low", "normal", "high"]}
            />

            <EnergyLevels setParameters={setParameters} />

            <MultiSelect
              placeholder="Mute Stems"
              values={parameters.muteStems}
              setValues={(muteStems) =>
                setParameters((params) => {
                  return { ...params, muteStems };
                })
              }
              data={["bc", "bs", "dr", "me", "fe", "ff"]}
            />

            <div className="remix_info__button" onClick={updateHandler}>
              <span>Update</span>
              <img src={Download} alt="" />
            </div>
          </div>
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="col col__1">
      <Notification text="Admin" />
      <AppHeader text="NO REMIXES AVAILABLE" />
      <Close onClick={() => context.setTab()} />

      <div className="remix_info">
        <div className="remix_info__details"></div>
      </div>
    </div>
  );
};

export default ControlPanel;
