import { useContext } from "react";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import Button from "../../shared/Button";
import Loader from "../../shared/Loader";
import "./CancelSubscription.scss";

const CancelSubscription = (props) => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const closeCancelSubscription = () => {
    props.clear();
  };

  const parseDate = () => {
    const date = new Date(context.subscriptionActiveUntil);

    const options = { year: "numeric", month: "short", day: "numeric" };
    let formattedDate = date.toLocaleDateString("en-US", options).toUpperCase();
    return formattedDate.replace(/,/g, "");
  };

  const handleCancel = async () => {
    await sendRequest(
      `${process.env.REACT_APP_API_URL}/payment/cancel`,
      "POST",
      null,
      {
        Authorization: "Bearer " + context.token,
      }
    );
    props.cancel();
    closeCancelSubscription();
  };

  return (
    <div className="cancel_sub">
      <div
        className="cancel_sub__backdrop"
        onClick={closeCancelSubscription}
      ></div>
      <div className="cancel_sub__body">
        <h2>Are you sure you want to cancel?</h2>
        <p>Your subscription will be active till {parseDate()}</p>
        {!isLoading && (
          <div className="cancel_sub__buttons">
            <Button text="Close" onClick={closeCancelSubscription} />
            <Button text="Proceed" onClick={handleCancel} />
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default CancelSubscription;
