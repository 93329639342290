import { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import Heart from "../../../assets/heart.svg";
import HeartFilled from "../../../assets/heart-filled.svg";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Verified from "../../../assets/verified-icon.png";
import "./SongTile.scss";

const SongTile = (props) => {
  const context = useContext(Context);
  const { sendRequest } = useHttpClient();

  const addToFavorite = async (e) => {
    e.stopPropagation();
    if (context.favorites.find((f) => f === props._id)) {
      context.setFavorites((fs) => fs.filter((f) => f !== props._id));
    } else {
      context.setFavorites((fs) => [...fs, props._id]);
    }

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/songs/add-to-playlist`,
      "POST",
      JSON.stringify({
        userId: context.userId,
        playlist: "Favorites",
        songId: props._id,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );
  };

  return (
    <div
      className={
        !props.post && !props.settings && context.activeSong === props._id
          ? "song_tile song_tile--active"
          : "song_tile"
      }
      onClick={
        props.onClick ? props.onClick : () => context.setActiveSong(props._id)
      }
    >
      {props.num && <p className="song_tile__num">{props.num}</p>}
      <img
        className="song_tile__img"
        src={props.imgURL || Placeholder}
        alt={props.name}
      />
      <div>
        <h3
          className={
            props.postActive
              ? "song_tile__header song_tile__header--post_active"
              : "song_tile__header"
          }
        >
          {props.name}
          {props.AIEnabled && <img src={Verified} alt="Verified Remix" />}
        </h3>
        <p className="song_tile__artist">{props.artist}</p>
      </div>

      {!props.settings && context.favorites && (
        <img
          className="song_tile__heart"
          id={props._id}
          src={
            context.favorites && context.favorites.includes(props._id)
              ? HeartFilled
              : Heart
          }
          onClick={addToFavorite}
          alt=""
        />
      )}
    </div>
  );
};

export default SongTile;
