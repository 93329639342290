import { useContext, useState, useEffect } from "react";
import { Context } from "../../utils/context";
import { useHttpClient } from "../../utils/http-hook";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import Input from "../shared/Input";
import Loader from "../shared/Loader";
import UserTile from "./components/UserTile";
import "./Users.scss";

const Users = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  // Fetch users when search term changes or on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/`,
        "POST",
        JSON.stringify({ search }),
        {
          Authorization: `Bearer ${context.token}`,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
        setUsers(responseData.users.filter((u) => u.id !== context.userId));
      }
    };

    fetchUsers();
  }, [search, sendRequest]);

  // Handlers for admin and verification changes
  const updateUserStatus = async (id, isVerified, adminStatus) => {
    const endpoint =
      adminStatus !== undefined ? "/update-admin" : "/verification";
    const propertyToUpdate =
      adminStatus !== undefined
        ? { admin: adminStatus }
        : { verified: isVerified };

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/users${endpoint}`,
      "POST",
      JSON.stringify({
        userId: id,
        ...propertyToUpdate,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );

    setUsers((currentUsers) =>
      currentUsers.map((user) =>
        user.id === id ? { ...user, ...propertyToUpdate } : user
      )
    );
  };

  const handleAdminChange = (id, admin) =>
    updateUserStatus(id, undefined, admin);

  const handleVerificationChange = (id, verified) =>
    updateUserStatus(id, verified);

  const deleteUser = async (id) => {
    await sendRequest(
      `${process.env.REACT_APP_API_URL}/users/delete-user`,
      "DELETE",
      JSON.stringify({
        userId: id,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );

    setUsers((currentUsers) => currentUsers.filter((user) => user.id !== id));
  };

  const updateCredits = async (id, credits) => {
    await sendRequest(
      `${process.env.REACT_APP_API_URL}/users/update-credits`,
      "POST",
      JSON.stringify({
        userId: id,
        aiCredits: credits,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );

    setUsers((currentUsers) =>
      currentUsers.map((user) =>
        user.id === id ? { ...user, aiCredits: credits } : user
      )
    );
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Users" />
      <Close onClick={() => context.setTab(null)} />

      <div className="users">
        <Input
          placeholder="Search"
          value={search}
          type="text"
          onInput={(e) => setSearch(e.target.value)}
        />

        {users &&
          users.map((u, i) => (
            <UserTile
              key={u.id}
              {...u}
              index={users.length - i}
              onAdminChange={handleAdminChange}
              onVerificationChange={handleVerificationChange}
              deleteUser={deleteUser}
              updateCredits={updateCredits}
            />
          ))}
        {isLoading && <Loader />}
      </div>
    </ModalContainer>
  );
};

export default Users;
