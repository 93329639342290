import { useContext } from "react";
import { Context } from "../../../utils/context.js";
import { useHttpClient } from "../../../utils/http-hook.js";
import timeSince from "../../../utils/time-since.js";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Delete from "../../../assets/delete.svg";
import Pin from "../../../assets/pin.svg";
import PinFilled from "../../../assets/pin-filled.svg";
import Fire from "../../../assets/fire.svg";
import FireFilled from "../../../assets/fire-filled.svg";
import Verified from "../../../assets/verified-icon.png";
import "./Comment.scss";

const Comment = (props) => {
  const context = useContext(Context);
  const { sendRequest } = useHttpClient();

  const pinHandler = async () => {
    const pinned = !props.pinned;

    sendRequest(
      `${process.env.REACT_APP_API_URL}/comments/pin`,
      "POST",
      JSON.stringify({
        commentId: props._id,
        pinned: pinned,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.token,
      }
    );

    // Update the state immutably to trigger re-render
    context.setPostComments((comments) => {
      return comments.map((comment) =>
        comment._id === props._id ? { ...comment, pinned: pinned } : comment
      );
    });
  };

  const deleteHandler = async () => {
    sendRequest(
      `${process.env.REACT_APP_API_URL}/comments/post`,
      "DELETE",
      JSON.stringify({
        id: context.activePost,
        commentId: props._id,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.token,
      }
    );

    context.setPostComments((comments) =>
      comments.filter((c) => c._id !== props._id)
    );
  };

  const likeHandler = async () => {
    sendRequest(
      `${process.env.REACT_APP_API_URL}/comments/like`,
      "POST",
      JSON.stringify({
        like: !props.likes.find((l) => l === context.userId),
        userId: context.userId,
        commentId: props._id,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    likeComment(props._id, context.userId);
  };

  const likeComment = (commentId, userId) => {
    context.setPostComments((cs) => {
      const activeComment = { ...cs.find((c) => c._id === commentId) };
      const comments = cs.filter((c) => c._id !== commentId);

      if (activeComment.likes.find((l) => l === userId)) {
        activeComment.likes = activeComment.likes.filter((l) => l !== userId);
      } else {
        activeComment.likes.push(userId);
      }

      comments.push(activeComment);
      comments.sort((a, b) => new Date(b.date) - new Date(a.date));

      return comments;
    });
  };

  return (
    <div className="comment">
      <div className="comment__header">
        <img
          className="comment__img"
          src={props.creator.img ? props.creator.img : Placeholder}
          alt=""
        />

        <div>
          <div className="comment__name">
            <span>{props.creator.name}</span>
            {props.creator.verified && <img src={Verified} alt="Verified" />}
          </div>
          <p className="comment__date">{timeSince(props.date)}</p>
        </div>

        <div className="comment__icons">
          {(props.pinned || context.admin) && (
            <img
              onClick={pinHandler}
              src={props.pinned ? PinFilled : Pin}
              alt="Pin"
            />
          )}
          {(context.admin || context.userId === props.creator.id) && (
            <img src={Delete} alt="Delete" onClick={deleteHandler} />
          )}
          <div className="comment__like" onClick={likeHandler}>
            <img
              src={
                props.likes.find((l) => l === context.userId)
                  ? FireFilled
                  : Fire
              }
              alt="like"
            />
            <span>{props.likes.length}</span>
          </div>
        </div>
      </div>

      <p className="comment__par">{props.text}</p>
    </div>
  );
};

export default Comment;
