import { useContext, useState } from "react";
import { useHttpClient } from "../../../utils/http-hook";
import { Context } from "../../../utils/context";
import Loader from "../../shared/Loader";
import Button from "../../../assets/play-pause.png";
import "./CommentForm.scss";

const PostCommentForm = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const [text, setText] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/comments/post`,
      "POST",
      JSON.stringify({
        text,
        creator: context.userId,
        id: context.activePost,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.token,
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }
      context.setPostComments((c) => [responseData.comment, ...c]);
      setText("");
    }
  };

  return (
    <form className="comment_form" onSubmit={submitHandler}>
      <textarea
        placeholder="SHARE SOMETHING"
        value={text}
        onInput={(e) => setText(e.target.value)}
      ></textarea>

      {!isLoading && (
        <div className="comment_form__publish">
          <button>
            <img src={Button} alt="Publish" />
          </button>
          <p>POST</p>
        </div>
      )}

      {isLoading && <Loader />}
    </form>
  );
};

export default PostCommentForm;
