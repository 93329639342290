import { useContext, useState, useEffect } from "react";
import { useHttpClient } from "../../../utils/http-hook";
import Generate from "../../../assets/generate-icon.svg";
import GenerateDark from "../../../assets/generate-icon-dark.svg";
import Community from "../../../assets/community-icon.svg";
import CommunityDark from "../../../assets/community-icon-dark.svg";
import Player from "../../../assets/player-icon.svg";
import PlayerDark from "../../../assets/player-icon-dark.svg";
import Comment from "../../../assets/comment.svg";
import CommentDark from "../../../assets/comment-dark.svg";
import Heart from "../../../assets/heart.svg";
import HeartDark from "../../../assets/heart-dark.svg";
import HeartFilled from "../../../assets/heart-filled.svg";
import HeartFilledDark from "../../../assets/heart-filled-dark.svg";
import { Context } from "../../../utils/context";

import "./PlayerButtons.scss";

const NavButtons = () => {
  const context = useContext(Context);
  const { sendRequest } = useHttpClient();

  const addToFavorite = async (e) => {
    e.stopPropagation();
    if (context.favorites.find((f) => f === context.activeSong)) {
      context.setFavorites((fs) => fs.filter((f) => f !== context.activeSong));
    } else {
      context.setFavorites((fs) => [...fs, context.activeSong]);
    }

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/songs/add-to-playlist`,
      "POST",
      JSON.stringify({
        userId: context.userId,
        playlist: "Favorites",
        songId: context.activeSong,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );
  };

  return (
    <div className="player_buttons">
      <div className="player_buttons__row">
        <div
          className="player_button player_button--active"
          onClick={() => context.setTab()}
        >
          <div className="player_button__num">
            <img
              className=""
              src={context.theme === "light" ? PlayerDark : Player}
              alt="Player"
            />
          </div>
          <p>Hard Drive</p>
        </div>

        <div
          className="player_button"
          onClick={() => context.setTab("community")}
        >
          <div className="player_button__num">
            <img
              className=""
              src={context.theme === "light" ? CommunityDark : Community}
              alt="Community"
            />
          </div>
          <p>Community</p>
        </div>

        <div
          className="player_button"
          onClick={() => context.setTab("generate")}
        >
          <div className="player_button__num">
            <img
              className=""
              src={context.theme === "light" ? GenerateDark : Generate}
              alt="Generator"
            />
          </div>
          <p>Generator</p>
        </div>
      </div>

      <div className="player_buttons__row">
        <div
          className="player_button"
          onClick={() => context.setTab("comments")}
        >
          <div className="player_button__num player_button__num--neutral">
            <img
              className=""
              src={context.theme === "light" ? CommentDark : Comment}
              alt="Comment"
            />
          </div>
          <p>Comment</p>
        </div>

        <div
          className={
            context.favorites && context.favorites.includes(context.activeSong)
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={addToFavorite}
        >
          <div className="player_button__num player_button__num--neutral">
            {context.favorites &&
              !context.favorites.includes(context.activeSong) && (
                <img
                  className=""
                  src={context.theme === "light" ? HeartDark : Heart}
                  alt="Heart"
                />
              )}

            {context.favorites &&
              context.favorites.includes(context.activeSong) && (
                <img
                  className=""
                  src={
                    context.theme === "light" ? HeartFilledDark : HeartFilled
                  }
                  alt="Heart"
                />
              )}
          </div>
          <p>Favorite</p>
        </div>
      </div>
    </div>
  );
};

export default NavButtons;
